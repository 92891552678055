























import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";

@Component
export default class RejectDialog extends Vue {
  @Prop({ required: false, default: false }) closeOnClickModal!: boolean;
  @Prop({ required: false, default: "拒绝审核" }) title!: string;

  show = false;
  rejectText = "";
  isLoaded = false;

  @Watch("show")
  onShowChange(val: boolean) {
    if (val) {
      this.isLoaded = true;
    } else {
      this.isLoaded = false;
      this.rejectText = "";
    }
  }

  handleSubmit() {
    if (!this.rejectText) {
      this.$message.error("拒绝原因不能为空");
      return;
    }
    this.show = false;
    this.$emit("rejected", this.rejectText);
  }
}
