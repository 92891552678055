














































































import { Component, Ref, Vue } from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import { AuditFlowScope, CustomFormType, VolunteerDto } from "@/api/appService";
import api from "@/api/index";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";

@Component({
  name: "VolunteerAuditDetail",
  components: {
    CustomFormDataTableDetail,
    CustomFormDetailView,
    ExportWord,
    AuditBlock,
  },
})
export default class VolunteerAuditDetail extends Vue {

  private dataId!: string;
  private formId!: string;

  isReady = false;

  selector = "detail-wrap";
  filename = "";
  defaultName = "合作方申请表-";
  styles = "";
  detail: VolunteerDto = {};

  loading = true;


  get auditApi(){
    return {canAudit: api.volunteer.canAudit,userAudit: api.volunteer.audit} as AuditApi
  }

  get hostType() {
    return CustomFormType.Volunteer;
  }

  created() {
    this.dataId = this.$route.params.id as string;
    this.formId = this.$route.params.formId as string;
    if ((this.dataId && this.hostType) || this.dataId) {
      this.isReady = true;
    } else {
      this.$message.error("缺少url参数 id 或 hostType，请检查!");
    }
  }

  getVolunteerProfile() {
    return new Promise<VolunteerDto>((resolve, reject) => {
      if (!this.loading) {
        this.loading = false;
        resolve(this.detail);
      } else {
        api.volunteer.get({ id: Number(this.dataId) }).then((res) => {
          this.detail = res;
          this.loading = false;
          resolve(res);
        });
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
