































import api from "@/api";
import { Component, Vue, Ref } from "vue-property-decorator";
import { CustomFormType } from "@/api/appService";
interface PartnerCustomFormItem {
  formId?: string;
  displayName: string;
  hostId: string;
}
@Component({
  name: "PartnerCustomForm",
})
export default class PartnerCustomForm extends Vue {
  items: PartnerCustomFormItem[] = [];

  async created() {
    await this.fetchDataDictionary();
  }
  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "OrgVolunteerApply",
        maxResultCount: 1000,
      })
      .then((res) => {
        this.items = res.items!.map((dictionary) => {
          return {
            displayName: dictionary.displayName,
            hostId: dictionary.id!.toString(),
            formId: "",
          } as PartnerCustomFormItem;
        });
        this.items.forEach((item) => {
          this.checkCustomForm(item);
        });
      });
  }
  checkCustomForm(item: PartnerCustomFormItem) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.Volunteer,
        hostId: item.hostId.toString(),
      })
      .then((res) => {
        item.formId = res;
      });
  }
  handleClick(item: PartnerCustomFormItem) {
    if (item.formId) {
      this.$router.push({
        name: "customFormDesign",
        query: {
          formId: item.formId,
          hostType: CustomFormType.Volunteer,
        },
      });
    } else {
      this.$router.push({
        name: "customFormDesign",
        query: {
          hostId: item.hostId,
          hostType: CustomFormType.Volunteer,
        },
      });
    }
  }
}
