

























import { Component, Vue, Watch } from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  CustomFormType,
  VolunteerCreateOrUpdateDto,
  VolunteerDto,
} from "@/api/appService";
import api from "@/api/index";

@Component({ name: "EditVolunteer", components: { CustomFormEdit } })
export default class EditVolunteer extends Vue {
  dataId = 0;

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  get hostType() {
    return CustomFormType.Volunteer;
  }

  defaultData: VolunteerDto = {};

  get getData() {
    if (this.dataId > 0) return api.volunteer.get({ id: this.dataId });
    else return this.defaultData;
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as VolunteerCreateOrUpdateDto;
        let fn;
        if (this.dataId) fn = api.volunteer.update;
        else fn = api.volunteer.create;

        fn({ body: data }).then((res) => {
          this.$message.success("更新成功");
          this.$router.push({
            name: "VolunteerList",
          });
        });
      } else {
        this.$message.error("请检查表单");
      }
    });
  }
}
