

















































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";
import EditServiceHistory from "@/views/volunteer/components/edit-serviceHistory.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {
  CustomFormType, ServiceRecordDto, TrainRecordDto,
  VolunteerDto,
  VolunteerServiceHistoryDto,
} from "@/api/appService";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import VolunteerServiceHistoryTimeline from "@/components/VolunteerServiceHistoryTimeline/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import VolunteerTrainRecordHistoryTimeline
  from "@/components/VolunteerServiceHistoryTimeline/volunteerTrainRecordHistoryTimeline.vue";
import VolunteerServiceRecordHistoryTimeline
  from "@/components/VolunteerServiceHistoryTimeline/volunteerServiceRecordHistoryTimeline.vue";

@Component({
  name: "VolunteerDetail",
  components: {
    VolunteerServiceRecordHistoryTimeline,
    VolunteerTrainRecordHistoryTimeline,
    VolunteerServiceHistoryTimeline,
    CustomFormDetailView,
    EditServiceHistory,
    CustomFormDataTableDetail,
    AttachmentsView,
    AuditBlock,
  },
})
export default class VolunteerDetail extends Vue {
  avatar = "/img/default-header.d45457fa.png";

  activeTab = "profile";

  serviceHistories: VolunteerServiceHistoryDto[] = [];
  trainRecordHistories: TrainRecordDto[] = [];
  serviceRecordHistories: ServiceRecordDto[] = [];
  detail: VolunteerDto = {};
  volunteerId = 0;
  loadingProfile = true;

  created() {
    if (this.$route.params.id) {
      this.volunteerId = Number(this.$route.params.id);

      this.loadVolunteerServiceHistories();
      this.loadTrainRecordHistories();
      this.loadServiceRecordHistories();
      this.getData();
    }
  }

  get hostType() {
    return CustomFormType.Volunteer;
  }

  get auditApi() {
    return {
      canAudit: api.volunteer.canAudit,
      userAudit: api.volunteer.audit
    } as AuditApi
  }

  get hostId() {
    return this.detail.id
  }

  loadVolunteerServiceHistories() {
    api.volunteerServiceHistory
      .getAll({volunteerId: this.volunteerId})
      .then((res) => {
        this.serviceHistories = res.items!;
      });
  }

  loadTrainRecordHistories() {
    api.trainRecord
      .getAll({volunteerId: this.volunteerId})
      .then((res) => {
        this.trainRecordHistories = res.items!;
      });
  }

  loadServiceRecordHistories() {
    api.serviceRecord
      .getAll({volunteerId: this.volunteerId})
      .then((res) => {
        this.serviceRecordHistories = res.items!;
      });
  }

  getVolunteerProfile() {
    return new Promise<VolunteerDto>((resolve, reject) => {
      if (!this.loadingProfile) {
        resolve(this.detail);
      } else {
        api.volunteer.get({id: this.volunteerId}).then((res) => {
          this.detail = res;
          this.loadingProfile = false;
          resolve(res);
        });
      }
    });
  }

  private back() {
    this.$router.back();
  }

  handleAddServiceHistory(row: VolunteerDto) {
    api.volunteerServiceHistory
      .getForEdit({
        id: 0,
      })
      .then(() => {
        let form = this.$refs.serviceHistoryForm as any;
        form.volunteerId = row.id;
        form.show = true;
      });
  }

  handleOnServiceHistorySaved() {
    this.loadVolunteerServiceHistories();
  }
}
