





































import {Component, Prop, Vue} from "vue-property-decorator";
import {ServiceRecordDto} from "../../api/appService";
import api from "@/api";

@Component({
  name: "volunteerServiceRecordHistoryTimeline",
})
export default class volunteerServiceRecordHistoryTimeline extends Vue {
  @Prop({required: false, default: 0})
  volunteerId!: number;

  @Prop({required: false, default: []})
  histories!: ServiceRecordDto[];

  loading = false;

  created() {
    if (this.volunteerId) {
      this.loading = true;
      this.loadHistories();
    } else {
      this.loading = false;
    }
  }

  loadHistories() {
    api.serviceRecord
      .getAll({volunteerId: this.volunteerId})
      .then((res) => {
        this.histories = res.items!;
        this.loading = false;
      });
  }
}
