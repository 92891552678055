
































import { Component, Prop, Vue } from "vue-property-decorator";
import { VolunteerServiceHistoryDto } from "../../api/appService";
import api from "@/api";

@Component({
  name: "VolunteerServiceHistoryTimeline",
})
export default class VolunteerServiceHistoryTimeline extends Vue {
  @Prop({ required: false, default: 0 })
  volunteerId!: number;

  @Prop({ required: false, default: [] })
  histories!: VolunteerServiceHistoryDto[];

  loading = false;

  created() {
    if (this.volunteerId) {
      this.loading = true;
      this.loadHistories();
    } else {
      this.loading = false;
    }
  }

  loadHistories() {
    api.volunteerServiceHistory
      .getAll({ volunteerId: this.volunteerId })
      .then((res) => {
        this.histories = res.items!;
        this.loading = false;
      });
  }
}
