









































































































import {Component, Vue, Prop, Watch, Ref} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import api from "@/api";
import {
  ActivityDto,
  ActivityStatus,
  VolunteerServiceHistoryCreateOrUpdateDto,
} from "@/api/appService";

@Component
export default class EditActivity extends Vue {
  @Ref() readonly dataForm!: ElForm;

  get dialogTitle() {
    return this.form!.id ? `编辑服务记录` : "新增服务记录";
  }

  activityList: ActivityDto[] = [];

  serviceTypeList: any = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      await api.activity.getAll({isVolunteerActivity: true, maxResultCount: 50}).then((res) => {
        this.activityList = res.items!;
      });

      await api.volunteerServiceHistory
        .getForEdit({id: this.form!.id})
        .then((res) => {
          this.form = res.data!;
          this.form.volunteerId = this.volunteerId;
        });

      await api.enumService
        .getValues({typeName: "ServiceType"})
        .then((res: any) => {
          this.serviceTypeList = res;
        });
    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  defaultData: VolunteerServiceHistoryCreateOrUpdateDto = {
    id: 0,
  };

  show = false;
  volunteerId = 0;

  form: VolunteerServiceHistoryCreateOrUpdateDto = {...this.defaultData};

  async save() {
    console.log(this.form);
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.volunteerServiceHistory.update({body: this.form});
        } else {
          await api.volunteerServiceHistory.create({body: this.form});
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  onSelectChange(e: number) {
    if (e) {
      let selection = this.activityList.find((x) => x.id === e);
      if (selection) {
        this.form.serviceStartTime = selection.activityStartTime;
        this.form.serviceEndTime = selection.activityEndTime;
        this.form.serviceHour = selection.totalHours;
      }
    }
  }

  roleRule = {
    activityId: [
      {
        required: true,
        message: "参加的活动 必填 ",
        trigger: "blur",
      },
    ],
    serviceHour: [
      {
        required: true,
        message: "服务时长 必填 ",
        trigger: "blur",
      },
    ],
    serviceContent: [
      {
        required: true,
        message: "服务内容 必填 ",
        trigger: "blur",
      },
    ],
    serviceType: [
      {
        required: true,
        message: "服务类型 必填 ",
        trigger: "blur",
      },
    ],
  };
}
