


























































































import api from "@/api"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, RoleDto,
  ServiceOrganizationDto, StudentDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
import ResetPassword from "@/views/volunteer/components/resetPassword.vue";

@Component({
  name: "RecordList",
  components: {
    ResetPassword,
    PagedTableView,
    AgileAuditTag,
    ExportButton
  }
})
export default class ServiceRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;

  editLinkId = 0;
  roleList: RoleDto[] = [];

  queryForm: any = {
    name: undefined,
    surname: undefined,
    phoneNumber: undefined,
    roleId: undefined,
  };

  // 获取表数据
  async fetchData(params: any) {
    params.isOnlyMembership = true;
    params.mode = "foundation";
    await api.role.getRoles().then((res) => {
      this.roleList = res.items!;
    })
    return await api.user.getAll(params);
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "reset": {
        this.handleReset(e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 查看详情
  handleReset(item: any) {
    this.editLinkId = item.id!;
    (this.$refs.editForm as any).show = true;
    console.log(this.editLinkId);
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
